
<template>
  <div>
    <app-drawer_menu drawer_menu_title="Letter"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <v-tabs background-color="white" color="fourth" v-if="access.read === 1">
      <v-tab id="tabform" href="#tab-form" @click="FirstLoad"> Form </v-tab>
      <v-tab href="#tab-list" @click="List"> List </v-tab>
      <v-tab-item value="tab-form">
        <v-form enctype="multipart/form-data" ref="form_letter" lazy-validation>
          <v-row class="mx-2 ma-1">
            <v-col cols="12" sm="12" md="12">
              <v-btn
                color="tertiary"
                class="white--text"
                :disabled="access.add === 0 ? true : false"
                rounded
                @click="Add"
                small
              >
                <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
              </v-btn>
              &nbsp;
              <v-btn
                color="primary"
                class="white--text"
                :disabled="access.add === 0 || access.edit === 0 ? true : false"
                rounded
                @click="Confirm('write', '')"
                small
              >
                <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
              </v-btn>
            </v-col>
            <!-- <v-col cols="12" sm="6" md="6" class="mt-n2">
              <v-select
                v-model="form.letter_key"
                :items="itemsmenu"
                label="Key"
                item-value="code_id"
                item-text="code_desc"
                multiple
                chips
                @click="ListMenu()"
                prepend-inner-icon="key"
              >
              </v-select>
            </v-col> -->
             <!-- Dept -->
             <v-col cols="12" sm="3" md="3" class="mt-3">
              <app-cb
                cb_type="code"
                cb_url="apiListCode"
                cb_url_parameter="code_group=LETTERTYPE&code_actived=Y"
                cb_title="Type"
                cb_id="letter_type"
                cb_desc="letter_type_desc"
                cb_rules="Please fill Type"
                cb_desc_readonly="readonly"
                cb_items_id="code_id"
                cb_items_desc="code_desc"
                :cb_value_id="form.letter_type"
                :cb_value_desc="form.letter_type_desc"
                @clicked="GetType"
              >
              </app-cb>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="mt-3" v-if="form.letter_type ==='SK'">
              <app-cb
                cb_type="code"
                cb_url="apiListCode"
                cb_url_parameter="code_group=LETTERCATEGORY&code_actived=Y"
                cb_title="Category"
                cb_id="letter_category"
                cb_desc="letter_category_desc"
                cb_rules="Please fill Category"
                cb_desc_readonly="readonly"
                cb_items_id="code_id"
                cb_items_desc="code_desc"
                :cb_value_id="form.letter_category"
                :cb_value_desc="form.letter_category_desc"
                @clicked="GetCategory"
              >
              </app-cb>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="mt-3">
              <app-cb
                cb_type="jobtitle"
                cb_url="apiListJobTitle"
                cb_url_parameter="jobtitle_actived=Y"
                cb_title="Job Title"
                cb_id="letter_jobtitle"
                cb_desc="letter_jobtitle_desc"
                cb_rules="Please fill Job Title"
                cb_desc_readonly="readonly"
                cb_items_id="jobtitle_id"
                cb_items_desc="jobtitle_desc"
                cb_items_additional_1="jobtitle_lettercode"
                :cb_value_id="form.letter_jobtitle"
                :cb_value_desc="form.letter_jobtitle_desc"
                @clicked="GetJobTitle"
              >
              </app-cb>
            </v-col>
            <!-- ID -->
            <v-col cols="12" :sm="form.letter_type ==='SK'?'3':'5'" :md="form.letter_type ==='SK'?'3':'5'">
              <v-text-field
                v-model="form.letter_id"
                :readonly="form.letter_id_readonly"
                :background-color="form.letter_id_readonly ? 'readonly' : ''"
                :maxlength="maxinput.id"
                :counter="maxinput.id"
                label="ID"
                placeholder="<<Automated ID>>"
                id="letter_id"
                prepend-inner-icon="tag"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
              <!-- Ref No -->
              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  v-model="form.letter_refno"
                  required
                  prepend-inner-icon="numbers"
                  :rules="form.letter_refno_rules"
                  :maxlength="maxinput.desc"
                  label="Ref No *"
                  placeholder="Ref No"
                  id="letter_refno"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-text-field>
              </v-col>
            <!-- Active -->
            <v-col cols="12" sm="2" md="2" class="mt-n5">
              <label class="caption">Active Status</label>
              <v-switch
                v-model="form.letter_actived"
                color="primary"
                id="form.letter_actived"
                :label="form.letter_actived !== true ? '' : 'Active'"
              ></v-switch>
            </v-col>
             <!-- Dept -->
             <v-col cols="12" sm="4" md="4" class="mt-n3">
              <app-cb
                cb_type="profile"
                cb_url="apiListProfile"
                cb_url_parameter="profile_actived=Y"
                cb_title="Client"
                cb_id="letter_client"
                cb_desc="letter_client_desc"
                :cb_rules="form.letter_type ==='PKS'?'Please fill Client':''"
                cb_desc_readonly="readonly"
                cb_items_id="profile_id"
                cb_items_desc="profile_name"
                :cb_value_id="form.letter_client"
                :cb_value_desc="form.letter_client_desc"
                @clicked="GetClient"
              >
              </app-cb>
            </v-col>

            <!-- Type -->
            <!-- <v-col cols="12" sm="2" md="2">
              <v-text-field
                prepend-inner-icon="list"
                v-model="form.letter_type"
                required
                :rules="form.letter_type_rules"
                :maxlength="maxinput.type"
                label="Type *"
                placeholder="Type"
                id="letter_type"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col> -->

            <!-- From -->
            <v-col cols="12" sm="4" md="4" class="mt-n6">
              <v-text-field
                v-model="form.letter_from"
                required
                prepend-inner-icon="keyboard"
                :rules="form.letter_from_rules"
                :maxlength="maxinput.from"
                label="From *"
                placeholder="From"
                id="letter_from"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>

            <!-- To -->
            <v-col cols="12" sm="4" md="4" class="mt-n6">
              <v-text-field
                prepend-inner-icon="keyboard"
                v-model="form.letter_to"
                required
                :rules="form.letter_to_rules"
                :maxlength="maxinput.to"
                label="To *"
                placeholder="To"
                id="letter_to"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>

            <!-- Subject -->
            <v-col cols="12" sm="6" md="6" class="mt-n6">
              <v-text-field
                prepend-inner-icon="bookmark"
                v-model="form.letter_subject"
                required
                :rules="form.letter_subject_rules"
                :maxlength="maxinput.subject"
                label="Subject *"
                placeholder="Subject"
                id="letter_subject"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>

            <!-- Key -->
            <!-- <v-col cols="12" sm="2" md="2">
				<v-text-field
				  v-model="form.letter_key"
				  required
				  :rules="form.letter_key_rules"
				  :maxlength="maxinput.key"
				  label="Key *"
				  placeholder="Key"
				  id="letter_key"
				  @keyup.enter="Confirm('write', '')"
				>
				</v-text-field>
			  </v-col> -->

            <!-- S Date -->
            <v-col cols="12" sm="2" md="2" class="mt-n6">
              <v-dialog
                ref="dialog_sdate"
                v-model="modal.sdate"
                :return-value.sync="form.letter_sdate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.letter_sdate"
                    label="Start Date"
                    prepend-inner-icon="event"
                    background-color="date"
                    v-on="on"
                    v-mask="'####-##-##'"
                    placeholder="YYYY-MM-DD"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.letter_sdate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.sdate = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_sdate.save(form.letter_sdate)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>

            <!-- E Date -->
            <v-col cols="12" sm="2" md="2" class="mt-n6">
              <v-dialog
                ref="dialog_edate"
                v-model="modal.edate"
                :return-value.sync="form.letter_edate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.letter_edate"
                    label="End Date"
                    prepend-inner-icon="event"
                    background-color="date"
                    v-on="on"
                    v-mask="'####-##-##'"
                    placeholder="YYYY-MM-DD"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.letter_edate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.edate = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_edate.save(form.letter_edate)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>
            <!-- Ref Date -->
            <v-col cols="12" sm="2" md="2" class="mt-n6">
              <v-dialog
                ref="dialog_refdate"
                v-model="modal.refdate"
                :return-value.sync="form.letter_refdate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.letter_refdate"
                    label="Ref Date"
                    prepend-inner-icon="event"
                    background-color="date"
                    v-on="on"
                    v-mask="'####-##-##'"
                    placeholder="YYYY-MM-DD"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.letter_refdate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.refdate = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_refdate.save(form.letter_refdate)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>
            <!-- Notes -->
            <v-col cols="12" :sm="form.letter_type ==='SK'?'4':'8'" :md="form.letter_type ==='SK'?'4':'8'" class="mt-n6">
              <v-text-field
                prepend-inner-icon="note"
                v-model="form.letter_notes"
                required
                :rules="form.letter_notes_rules"
                :maxlength="maxinput.desc"
                label="Notes *"
                placeholder="Notes"
                id="letter_notes"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>

            <!-- Client -->
            <!-- <v-col cols="12" sm="2" md="2">
              <v-text-field
                prepend-inner-icon="person"
                v-model="form.letter_client"
                required
                :rules="form.letter_client_rules"
                :maxlength="maxinput.desc"
                label="Client *"
                placeholder="Client"
                id="letter_client"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col> -->
            <!-- Agreement -->
            <!-- <v-col cols="12" sm="2" md="2">
              <v-text-field
                prepend-inner-icon="handshake"
                v-model="form.letter_agreement"
                required
                :rules="form.letter_agreement_rules"
                :maxlength="maxinput.desc"
                label="Agreement *"
                placeholder="Agreement"
                id="letter_agreement"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col> -->

            <!-- Dept -->
            <v-col cols="12" sm="4" md="4" class="mt-n3">
              <app-cb
                cb_type="department"
                cb_url="apiListDepartment"
                cb_url_parameter="department_actived=Y"
                cb_title="Dept"
                cb_id="letter_dept"
                cb_desc="letter_dept_desc"
                cb_rules=""
                cb_desc_readonly="readonly"
                cb_items_id="department_id"
                cb_items_desc="department_desc"
                :cb_value_id="form.letter_dept"
                :cb_value_desc="form.letter_dept_desc"
                @clicked="GetDepartment"
              >
              </app-cb>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="form.letter_id">
              <v-subheader class="title">Attachment</v-subheader>
              <app-attachment modul="letter" :refno="form.letter_id"/>
            </v-col>
          </v-row>
          <v-row class="mx-2 mt-n6">
            <v-col cols="12" sm="5" md="5">
              <div v-show="url_type !== 'add' ? true : false">
                <v-checkbox v-model="cont" label="Show Info"></v-checkbox>
                <v-banner
                  v-model="cont"
                  single-line
                  transition="slide-y-transition"
                >
                  <pre>
  Created Date 	 : {{form.letter_cdate}}
  Created User 	 : <span class="cursor" @click="ShowUsers(form.letter_cuser)">{{form.letter_cuser}}</span>
  Last Update Date : {{form.letter_ldate}}
  Last Update User : <span class="cursor" @click="ShowUsers(form.letter_luser)">{{form.letter_luser}}</span>
  </pre>
                </v-banner>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item value="tab-list">
        <template>
          <v-card-title class="ml-n4">
            <v-btn
              :color="selected.length === items.length ? 'secondary' : ''"
              @click="SelectAllPage"
              class="mr-2"
              title="select all page"
              small
            >
              select all page
            </v-btn>
            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multidelete', '')"
              class="mr-2"
              v-if="selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>{{$vars.V('icon_delete')}}</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="btn_search === false"
              @click="btn_search = true"
              small
            >
              <v-icon>{{$vars.V('icon_search')}}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              @click:append="btn_search = false"
              label="Search"
              single-line
              hide-details
              v-if="btn_search"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :value="selected"
            :headers="headers"
            :items="items"
            :search="search"
            @input="Check($event)"
            item-key="letter_id"
            show-select
            class="elevation-1"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
            }"
            dense
            :loading="loading.list"
            loading-text="Loading... Please wait"
          >
          <template v-slot:item.letter_type="{ item }">
            {{item.letter_type}}
            <div v-if="item.letter_category"> - {{item.letter_category_desc}}</div>
          </template>
            <template v-slot:item.letter_client="{ item }">
              {{item.letter_client}} - {{item.letter_client_desc}}
            </template>
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="Edit(item.letter_id)"
                :disabled="access.edit === 0 ? true : false"
                title="edit"
              >
              {{$vars.V('icon_edit')}}
              </v-icon>
              <v-icon
                small
                @click="Confirm('delete', item.letter_id)"
                :disabled="access.delete === 0 ? true : false"
                title="delete"
              >
              {{$vars.V('icon_delete')}}
              </v-icon>
            </template>
          </v-data-table>
        </template>
      </v-tab-item>
    </v-tabs>

    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="290"
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-dialog
      v-model="confirm.dialog"
      :max-width="Variable('confirm', 0)"
      persistent
    >
      <v-card :color="Variable('confirm', 4)">
        <v-card-title :class="Variable('confirm', 3)"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            :color="Variable('confirm', 1)"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
            >{{ confirm.text }}</v-btn
          >
          <v-btn :color="Variable('confirm', 2)" @click="confirm.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog Users -->
    <app-users
      :visible="dialog.users"
      :users_id="form.display_users"
      @close="dialog.users = false"
    ></app-users>
  </div>
</template>
  <script>
export default {
  data: () => ({
    url_type: "add",
    cont: false,
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    maxinput: {
      id: 50,
      type: 10,
      from: 150,
      to: 150,
      subject: 255,
      key: 10,
      notes: 255,
      client: 5,
      agreement: 5,
      dept: 10,
      refno: 50,
    },
    snackbar: {
      dialog: false,
      color: "primary",
      text: "",
      timeout: 3000,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    selected: [],
    search: "",
    headers: [
      { text: "Actions", value: "action", sortable: false },
      {
        text: "ID",
        align: "left",
        value: "letter_id",
      },
      { text: "Type", value: "letter_type" },
      { text: "Refno", value: "letter_refno" },
      { text: "Subject", value: "letter_subject" },
      { text: "Client", value: "letter_client" },
      { text: "Actived", value: "letter_actived" },
    ],
    remarks: {
      feedback: "",
      dialog: false,
    },
    items: [],
    itemsmenu: [],
    accessmenu: [],
    loading: {
      page: true,
      list: false,
    },
    btn_search: true,
    modal: {
      sdate: false,
      edate: false,
      refdate: false,
    },
    form: {
      letter_id: "",
      letter_type: "",
      letter_type_desc: "",
      letter_category: "",
      letter_category_desc: "",
      letter_from: "",
      letter_to: "",
      letter_subject: "",
      letter_key: [],
      letter_sdate: "",
      letter_edate: "",
      letter_notes: "",
      letter_client: "",
      letter_client_desc: "",
      letter_agreement: "",
      letter_refdate: "",
      letter_dept: "",
      letter_dept_desc: "",
      letter_jobtitle: "",
      letter_jobtitle_desc: "",
      letter_jobtitle_code: "",
      letter_refno: "",
      letter_actived: true,
      letter_id_readonly: false,
      letter_id_rules: [(v) => !!v || "Please fill ID"],
      letter_letter_type_rules: [(v) => !!v || "Please fill Type"],
      letter_letter_from_rules: [(v) => !!v || "Please fill From"],
      letter_letter_to_rules: [(v) => !!v || "Please fill To"],
      letter_letter_subject_rules: [(v) => !!v || "Please fill Subject"],
      letter_letter_key_rules: [(v) => !!v || "Please fill Key"],
      letter_letter_sdate_rules: [(v) => !!v || "Please fill S Date"],
      letter_letter_edate_rules: [(v) => !!v || "Please fill E Date"],
      letter_letter_notes_rules: [(v) => !!v || "Please fill Notes"],
      letter_letter_client_rules: [(v) => !!v || "Please fill Client"],
      letter_letter_agreement_rules: [(v) => !!v || "Please fill Agreement"],
      letter_letter_refdate_rules: [(v) => !!v || "Please fill Ref Date"],
      letter_letter_dept_rules: [(v) => !!v || "Please fill Dept"],
      letter_letter_refno_rules: [(v) => !!v || "Please fill Ref No"],
      display_users: "",
    },
    dialog: {
      users: false,
    },
  }),
  created() {
    this.FirstLoad()
  },
  //   computed: {
  //     selectedItemsText() {
  //       return this.form.letter_key.join(", ")
  //     },
  //   },
  methods: {
    FirstLoad() {
      //this function use for validating the screen with user privilleges
      this.form.letter_sdate = this.$functions.TodayYYYYMMDD()
      this.form.letter_edate = this.$functions.TodayYYYYMMDD()
      this.form.letter_refdate = this.$functions.TodayYYYYMMDD()
      this.Access()
    },
    combinedText(item) {
      return `${item.code_desc} - ${item.code_group}`
    },
    //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
    Access() {
      let modul = "letter"
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data
          if (priv.length > 0) {
            this.access.read = priv[0].usersmenu_read
            this.access.add = priv[0].usersmenu_add
            this.access.edit = priv[0].usersmenu_edit
            this.access.delete = priv[0].usersmenu_delete
            if (priv[0].usersmenu_read === 0) {
              this.SnackBar(
                true,
                "error",
                this.$functions.NoPriviledge(modul),
                0
              )
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            )
          }
          this.loading.page = false
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0)
          this.loading.page = false
        })
    },
    SelectAllPage() {
      this.selected = this.selected === this.items ? [] : this.items
    },
    Check(value) {
      this.selected = value
    },
    //Function for get all data from table letter with API in mod_letter.go
    List() {
      this.loading.list = true
      let formdata = {
        letter_id: "",
        letter_type: "",
        order_by: "letter_id",
        order_type: "ASC",
        limit: this.limit,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListLetter"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0)
            } else {
              this.items = feedback
            }
          } else {
            this.items = feedback
            this.SnackBar(true, "error", this.$functions.NoData(), 0)
          }
          this.loading.list = false
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000)
          this.loading.list = false
        })
    },
    //Pop Up message for validating (Ok or Cancel) your proccess
    Confirm(flag, id) {
      if (flag === "write") {
        if (this.$refs.form_letter.validate()) {
          this.confirm.dialog = true
          this.confirm.title = "Save"
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        }
      } else if (flag === "delete") {
        this.url_type = "delete"
        this.confirm.dialog = true
        this.confirm.title = "Delete `" + id + "`"
        this.letter_id = id
        setTimeout(function () {
          document.getElementById("dialog").focus()
        }, 500)
      } else if (flag === "multidelete") {
        this.url_type = "multidelete"
        this.confirm.dialog = true
        this.confirm.title = "Delete `" + this.selected.length + "` data"
        setTimeout(function () {
          document.getElementById("dialog").focus()
        }, 500)
      }
    },

    //Function to request insert data to table letter 'The API will validating your data, include the user who process the data' (go to APIWriteLetter with url_type = "add")
    Add() {
      this.url_type = "add"
      this.form.letter_id = ""
      this.form.letter_type = ""
      this.form.letter_type_desc = ""
      this.form.letter_category = ""
      this.form.letter_category_desc = ""
      this.form.letter_from = ""
      this.form.letter_to = ""
      this.form.letter_subject = ""
      this.form.letter_key = ""
      this.form.letter_sdate = ""
      this.form.letter_edate = ""
      this.form.letter_notes = ""
      this.form.letter_client = ""
      this.form.letter_agreement = ""
      this.form.letter_refdate = ""
      this.form.letter_dept = ""
      this.form.letter_dept_desc = ""
      this.form.letter_refno = ""
      this.form.letter_jobtitle = ""
      this.form.letter_jobtitle_desc = ""
      this.form.letter_actived = true

      this.form.letter_id_readonly = false
      setTimeout(function () {
        document.getElementById("letter_id").focus()
      }, 500)
    },
    //Function to request update data to table letter 'The API will validating your data, include the user who process the data' (go to APIWriteLetter with url_type = "edit")
    Edit(id) {
      this.form.letter_id = ''
      this.loading.list = true
      let formdata = {
        letter_id: id,
        letter_type: "",
        order_by: "letter_id",
        order_type: "ASC",
        limit: this.limit,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListLetter"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              document.getElementById("tabform").click()
              this.url_type = "edit"
              this.form.letter_id = feedback[0].letter_id
              this.form.letter_type = feedback[0].letter_type
              this.form.letter_type_desc = feedback[0].letter_type_desc
              this.form.letter_category = feedback[0].letter_category
              this.form.letter_category_desc = feedback[0].letter_category_desc
              this.form.letter_from = feedback[0].letter_from
              this.form.letter_to = feedback[0].letter_to
              this.form.letter_subject = feedback[0].letter_subject

              this.form.letter_key = feedback[0].letter_key.split(",")
              this.form.letter_sdate = feedback[0].letter_sdate
              this.form.letter_edate = feedback[0].letter_edate
              this.form.letter_notes = feedback[0].letter_notes
              this.form.letter_client = feedback[0].letter_client
              this.form.letter_client_desc = feedback[0].letter_client_desc
              this.form.letter_agreement = feedback[0].letter_agreement
              this.form.letter_refdate = feedback[0].letter_refdate
              this.form.letter_dept = feedback[0].letter_dept
              this.form.letter_dept_desc = feedback[0].letter_dept_desc
              this.form.letter_jobtitle = feedback[0].letter_jobtitle
              this.form.letter_jobtitle_desc = feedback[0].letter_jobtitle_desc
              this.form.letter_refno = feedback[0].letter_refno
              this.form.letter_actived = this.$functions.TrueOrFalse(
                feedback[0].letter_actived
              )
              this.form.letter_cdate = feedback[0].letter_cdate
              this.form.letter_cuser = feedback[0].letter_cuser
              this.form.letter_ldate = feedback[0].letter_ldate
              this.form.letter_luser = feedback[0].letter_luser

              this.form.letter_id_readonly = true
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", feedback, 0)
          }
          this.loading.list = false
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
          this.loading.list = false
        })
    },
    Delete(id) {
      let formdata = {
        url_type: "delete",
        letter_id: id,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteLetter"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "primary", "Delete Success", 3000)
              this.confirm.dialog = false
              this.confirm.text = "Ok"
              this.selected = []
              this.List()
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
    },
    MultiProcess() {
      let multiData = JSON.stringify(this.selected)
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteLetter"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback[0].feedback === "Y") {
            let feed
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
              this.remarks.dialog = true
            }
            this.SnackBar(true, "primary", feed, 0)
            this.confirm.dialog = false
            this.confirm.text = "Ok"
            this.selected = []
            this.List()
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback[0].feedback
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
    },
    Loading() {
      this.confirm.text = "Loading..."
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write()
      } else if (this.url_type === "delete") {
        this.Delete(this.letter_id)
      } else if (this.url_type === "multidelete") {
        this.MultiProcess("delete")
      }
    },
    Write() {
      let formdata = {
        url_type: this.url_type,
        letter_id: this.form.letter_id,
        letter_type: this.form.letter_type,
        letter_from: this.form.letter_from,
        letter_to: this.form.letter_to,
        letter_subject: this.form.letter_subject,
        // letter_key: this.form.letter_key.join(", "),
        letter_sdate: this.form.letter_sdate,
        letter_edate: this.form.letter_edate,
        letter_notes: this.form.letter_notes,
        letter_client: this.form.letter_client,
        letter_agreement: this.form.letter_agreement,
        letter_refdate: this.form.letter_refdate,
        letter_dept: this.form.letter_dept,
        letter_refno: this.form.letter_refno,
        letter_category: this.form.letter_category,
        letter_jobtitle: this.form.letter_jobtitle,
        letter_jobtitle_code: this.form.letter_jobtitle_code,
        letter_actived: this.$functions.ActivedFlag(this.form.letter_actived),
      }

      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteLetter"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "primary", "Save Success", 3000)
              this.confirm.dialog = false
              this.confirm.text = "Ok"
              if (this.url_type === "add") {
                this.form.letter_cuser = feedback[0].feedback_users_id
                this.form.letter_cdate = feedback[0].feedback_users_date
              }
              this.form.letter_luser = feedback[0].feedback_users_id
              this.form.letter_ldate = feedback[0].feedback_users_date
              this.form.letter_id = feedback[0].feedback_id
              this.url_type = "edit"
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
    },
    handleSelectChange(selectedValues) {
      this.form.letter_key = selectedValues.map((value) => value.menu_id)
    },
    //Function for get all data from table menu
    ListMenu() {
      this.loading.list = true
      let formdata = {
        code_id: "",
        order_type: "ASC",
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListCode"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          console.log("listmenu", feedback)
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0)
            } else {
              this.itemsmenu = feedback
            }
          } else {
            this.itemsmenu = feedback
            this.SnackBar(true, "error", this.$functions.NoData(), 0)
          }
          this.loading.list = false
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000)
          this.loading.list = false
        })
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      }
      if (color === "error") {
        this.confirm.dialog = false
        this.confirm.text = "Ok"
      }
    },
    ShowUsers(id) {
      this.dialog.users = true
      this.form.display_users = id
    },
    Variable(flag, position) {
      return this.$functions.Variable(flag, position)
    },
    GetType (value) {
      if (value !== undefined) {            
        let splits  = this.$functions.Split(value, '|')
        this.form.letter_type = splits[0]
        this.form.letter_type_desc = splits[1]
      }
    },
    GetCategory (value) {
      if (value !== undefined) {            
        let splits  = this.$functions.Split(value, '|')
        this.form.letter_category = splits[0]
        this.form.letter_category_desc = splits[1]
      }
    },
    GetClient (value) {
      if (value !== undefined) {            
        let splits  = this.$functions.Split(value, '|')
        this.form.letter_client = splits[0]
        this.form.letter_client_desc = splits[1]
      }
    },
    GetDepartment (value) {
      if (value !== undefined) {            
        let splits  = this.$functions.Split(value, '|')
        this.form.letter_dept = splits[0]
        this.form.letter_dept_desc = splits[1]
      }
    },
    GetJobTitle (value) {
      if (value !== undefined) {            
        let splits  = this.$functions.Split(value, '|')
        this.form.letter_jobtitle = splits[0]
        this.form.letter_jobtitle_desc = splits[1]
        this.form.letter_jobtitle_code = splits[3]
      }
    },
  },
}
</script>