<template>
  <div>
  	<app-drawer_menu drawer_menu_title="Map Upload"></app-drawer_menu>
    <v-progress-linear
            indeterminate
            color="fourth"
            rounded
            v-if="loading.page"
	    ></v-progress-linear>
    <v-tabs background-color="white" color="fourth" v-if="this.access.read === 1">
      <!-- Start Tab Menu -->
        <!-- Form Tab Menu -->
        <v-tab id="tabform" href="#tab-form" @click="FirstLoad">
          Form
        </v-tab>
        <!-- List Tab Menu -->
        <v-tab href="#tab-list" @click="List">
          List
        </v-tab>
      <!-- End Tab Menu -->

      <!-- Start Form Tab Item -->
        <v-tab-item value="tab-form">
          <!-- Start Form Map Header -->
            <v-form enctype="multipart/form-data" ref="form" lazy-validation>
              <v-row class="mx-2 ma-1">
                <!-- Form Button -->
                <v-col cols="12" sm="12" md="12">
                  <v-btn color="tertiary" class="white--text" :disabled="access.add === 0 ? true:false"  depressed @click="Add" small>
                    <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
                  </v-btn> 
                    &nbsp;
                  <v-btn color="primary" class="white--text" :disabled="access.add === 0 || access.edit === 0 ? true:false" depressed @click="Confirm('write','')"  small>
                    <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
                  </v-btn>
                </v-col>
                <!-- Form Input -->
                  <v-row class="mx-0">
                    <v-col cols="12" sm="6" md="6">
                      <!-- Map Group -->
                      <v-combobox v-model="form.mapheader_type" required :rules="form.mapheader_type_rules" :items="mapheader_type" label="Map Group" placeholder="Map Group" hint="(UP:Certificate, CL:Claim, BC:Batch Cancellation, BE:Batch Endorsment)" persistent-hint id="map_group" @keyup.enter="Confirm('write','')" class="body-2">
                      </v-combobox>

                      <!-- Map Code -->
                      <v-text-field v-model="form.mapheader_id" :readonly="form.mapheader_id_readonly" label="Map Code" placeholder="<<Automated ID>>">
                      </v-text-field>

                      <!-- Map Description -->
                      <v-text-field v-model="form.mapheader_desc" required :rules="form.mapheader_desc_rules" label="Map Description" placeholder="Map Description" id="map_desc" @keyup.enter="Confirm('write','')">
                      </v-text-field>

                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-row class="mx-0">
                      <!-- Partial Upload -->
                      <v-col cols="12" sm="12" md="4">
                        <label class="body-2">Partial Upload :</label>
                        <v-switch v-model="form.mapheader_partial" :label="form.mapheader_partial === true ? 'Yes' : '' ">
                        </v-switch>
                      </v-col>
                      <!-- Create Profile -->
                      <v-col cols="12" sm="12" md="4">
                        <label class="body-2">Create Profile :</label>
                        <v-switch v-model="form.mapheader_createprofile" :label="form.mapheader_reference === true ? 'Yes' : '' ">
                        </v-switch>
                      </v-col>

                      <!-- Duplicate Ref No -->
                      <v-col cols="12" sm="12" md="4">
                        <label class="body-2">Duplicate Ref No :</label>
                        <v-switch v-model="form.mapheader_duplicaterefno" :label="form.mapheader_allowed === true ? 'Yes' : '' ">
                        </v-switch>
                      </v-col>
                      </v-row>

                      <!-- Active Status -->
                      <v-col cols="12" sm="12" md="12"  class="mt-n5">
                        <label class="body-2">Active Status</label>
                        <v-switch v-model="form.mapheader_actived" color="primary" :label="form.mapheader_actived !== true ? '' : 'Active'">
                        </v-switch>
                      </v-col>
                    </v-col>
                  </v-row>
                  <v-row class="mx-2">
				<v-col cols="12" sm="5" md="5">
				<div v-show="url_type !== 'add' ? true:false ">
			   		<v-checkbox v-model="cont" label="Show Info"></v-checkbox>
					<v-banner v-model="cont" single-line transition="slide-y-transition">
<pre>
Created Date 	 : {{form.mapheader_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.mapheader_cuser)">{{form.mapheader_cuser}}</span>
Last Update Date : {{form.mapheader_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.mapheader_luser)">{{form.mapheader_luser}}</span>
</pre>
					</v-banner>
				</div>
				</v-col>
            </v-row>
              </v-row>
            </v-form>
          <!-- End Form Map Header -->

          <v-spacer></v-spacer>

          <!-- Start Child Map Header -->
            <v-tabs background-color="white" color="secondary">
              <!-- Start Tab Menu -->
                <!-- Map Detail Tab Menu -->
                <v-tab href="#tab-mapdetail" v-if="url_type !== 'add'" @click="ListMapDetail(form.mapheader_id)">
                  Map Detail
                </v-tab>
                <!-- Map Campaign Tab Menu -->
                <v-tab href="#tab-mapcampaign" v-if="url_type !== 'add'" @click="ListMapCampaign(form.mapheader_id)">
                  Map Campaign
                </v-tab>
              <!-- End Tab Menu -->
              
              <!-- Start Map Detail Tab Item -->
                <v-tab-item value="tab-mapdetail">
                  <!-- <v-row class="mx-2"> -->
                  <!-- Start Table Map Detail -->
                    <v-col cols="12" sm="12" md="12">
                      <v-btn :color="mapdetail.table.selected.length === mapdetail.table.items.length ? 'fourth':''" @click="SelectAllMapDetail" title="select all" class="mr-2" small>
                        select all
                      </v-btn>
                      <v-btn @click="OpenMapDetail" title="select all" class="mr-2" small>
                        Add
                      </v-btn>
                      <v-btn color="error" title="delete selected" @click="Confirm('multideletemapdetail','')" class="mr-2" v-if="mapdetail.table.selected.length > 0" :disabled="access.delete === 0 ? true:false" small>
                        <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                      </v-btn>
                      <v-btn color="primary" title="search" v-if="mapdetail.table.search_button === false" @click="SearchMapDetail" small>
                        <v-icon>{{$vars.V('icon_search')}}</v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-text-field 
                        id              ="searchmapdetail" 
                        v-model         ="mapdetail.table.search" 
                        v-if            ="mapdetail.table.search_button" 
                        @click:append   ="mapdetail.table.search_button = false" 
                        @keyup.enter    ="ListMapDetail(form.mapheader_id)"
                        label           ="Type and Enter" 
                        append-icon     ="search" 
                        single-line 
                        hide-details 
                        clearable 
                      ></v-text-field>
                    </v-col>

                      <v-data-table
                        class           ="elevation-1"
                        item-key        ="mapdetail_to"
                        v-model         ="mapdetail.table.selected"
                        :headers        ="mapdetail.table.data"
                        :items          ="mapdetail.table.items"
                        show-select
                        :footer-props   ="{
                          showFirstLastPage:  true,
                          firstIcon:          'first_page',
                          lastIcon:           'last_page',
                          prevIcon:           'keyboard_arrow_left',
                          nextIcon:           'keyboard_arrow_right'
                        }"
                        dense
                        :loading  ="loading.list"
                        loading-text="Loading... Please wait"
                        height          ="300"
                        fixed-header
                        hide-default-footer
                        disable-pagination
                      >
                        <template v-slot:item.action="{ item }">
                          <v-icon small class="mr-2" @click="OpenMapValue(item.mapdetail_headerid, item.mapdetail_from, item.mapdetail_to)" v-if ="item.mapdetail_mapvalue === 'Y' ? true:false">
                            new_label
                          </v-icon>
                          <v-icon 
                            class="mr-2" 
                            @click="EditMapDetail(
                              item.mapdetail_headerid, 
                              item.mapdetail_from,
                              item.mapdetail_to,
                              item.mapdetail_type,
                              item.mapdetail_defaultvalue,
                              item.mapdetail_func,
                              item.mapdetail_param_1,
                              item.mapdetail_param_2,
                              item.mapdetail_compulsory,
                              item.mapdetail_allowspace,
                              item.mapdetail_touppercase,
                              item.mapdetail_mapvalue,
                            )" 
                            :disabled ="access.edit === 0 ? true:false"
                            small 
                          >
                            edit
                          </v-icon>
                          <v-icon small @click="Confirm('deletemapdetail',item.mapdetail_headerid, item.mapdetail_from)" :disabled="access.delete === 0 ? true:false">
                            delete
                          </v-icon>
                        </template>
                      </v-data-table>
                  <!-- End Table Map Detail -->
                  <!-- </v-row> -->
                </v-tab-item>
              <!-- End Map Detail Tab Item -->

              <!-- Start Map Campaign Tab Item -->
                <v-tab-item value="tab-mapcampaign">
                  <!-- Menu Table Map Campaign -->
                  <v-col cols="12" sm="12" md="12">
                    <v-btn :color="mapcampaign.table.selected.length === mapcampaign.table.items.length ? 'fourth':''" @click="SelectAllMapCampaign" title="select all" class="mr-2" small>
                      select all
                    </v-btn>
                    <v-btn @click="OpenMapCampaign" class="mr-2" title="select all" small>
                      Add
                    </v-btn>
                    <v-btn color="error" title="delete selected" @click="Confirm('multideletemapfield','')" class="mr-2" v-if="mapcampaign.table.selected.length > 0" :disabled="access.delete === 0 ? true:false" small>
                      <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                    </v-btn>
                    <v-btn color="primary" title="search" v-if="mapcampaign.table.search_button === false" @click="SearchMapCampaign" small>
                      <v-icon>{{$vars.V('icon_search')}}</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="mapcampaign.table.search" append-icon="search" @click:append="mapcampaign.table.search_button = false" label="Type and Enter" single-line hide-campaigns v-if="mapcampaign.table.search_button" id="mapcampaign.table.search" clearable @keyup.enter="ListMapCampaign(form.mapheader_id)"></v-text-field>
                  </v-col>
                  <!-- Start Table Map Campaign -->
                  <v-data-table
                  v-model="mapcampaign.table.selected"
                  :headers="mapcampaign.table.data"
                  :items="mapcampaign.table.items"
                  class="elevation-1"
                  item-key="mapcampaign_campaignid"
                  show-select
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right'
                  }"
                  dense
                  :loading  ="loading.list"
                  loading-text="Loading... Please wait"
                  >
                  <template v-slot:item.mapcampaign_subclass="{ item }">
                    {{item.mapcampaign_subclass}} - {{item.mapcampaign_subclassdesc}}
                  </template>
                    <template v-slot:item.action="{ item }">
                      <!-- <v-icon small class="mr-2" @click="EditCampaign(item.mapheader_id, item.mapcampaign_id)" :disabled ="access.edit === 0 ? true:false">
                        edit
                      </v-icon> -->
                      <v-icon small @click="Confirm('deletemapcampaign',item.mapcampaign_headerid, item.mapcampaign_campaignid)" :disabled="access.delete === 0 ? true:false">
                        delete
                      </v-icon>
                    </template>
                  </v-data-table>
                  <!-- Start Table Map Campaign -->
                </v-tab-item>
              <!-- End Map Campaign Tab Item -->
            </v-tabs>
          <!-- End Child Map Header -->
        </v-tab-item>
      <!-- End Form Tab Item -->

      <!-- Start List Tab Item -->
        <v-tab-item value="tab-list">
          <!-- Start Table Map Header -->
            <template>
              <v-card-title 
			  		class="ml-n4"
			  	>
			  		<v-btn 
					  	:color 	="selected.length === mapHeadersItems.length ? 'secondary':''"
					  	@click	="SelectAllPage"
					  	class	="mr-2"
					  	:title="$vars.V('txt_select_all_page')"
					  	small
					  	>{{$vars.V('txt_select_all_page')}}
              </v-btn>
                <v-btn 
                  color     ="error"
                  title 	  ="delete selected"
                  @click 	  ="Confirm('multidelete','')"
                  class	  ="mr-2"
                  v-if  	  ="selected.length > 0"
                  :disabled ="access.delete === 0 ? true:false"
                  small>
                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                </v-btn>
                <v-btn 
                  color  ="primary"
                  title  ="search"
                  v-if   ="btn_search === false"
                  @click ="btn_search = true"
                  small>
                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field
            v-model="search"
            append-icon="search"
            @click:append="btn_search = false"
            label="Search"
            single-line
            hide-details
            v-if ="btn_search"
            ></v-text-field>
            </v-card-title>
            <v-data-table
              :value="selected"
              :headers="mapHeaders"
              :items="mapHeadersItems"
              :search="search"
              @input="Check($event)"
              item-key="mapheader_id"
              show-select
              class="elevation-1"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'first_page',
                lastIcon: 'last_page',
                prevIcon: 'keyboard_arrow_left',
                nextIcon: 'keyboard_arrow_right'
              }"
              dense
              :loading 	="loading.list"
              loading-text="Loading... Please wait"
            >
              <template v-slot:item.action="{ item }">
                <v-icon
                  small
                  class	  ="mr-2"
                  @click	  ="Edit(item.mapheader_id)"
                  :disabled ="access.edit === 0 ? true:false"
                  title 	  ="edit"
                >
                  {{$vars.V('icon_edit')}}
                </v-icon>|
                <v-icon
                  small
                  @click	  ="Confirm('delete',item.mapheader_id)"
                  :disabled ="access.delete === 0 ? true:false"
                  title 	  ="delete"
                >
                  {{$vars.V('icon_delete')}}
                </v-icon>
              </template>
            </v-data-table>
          </template>
          <!-- End Table Map Header -->
        </v-tab-item>
      <!-- End List Tab Item -->
    </v-tabs>

    <!-- start dialog add map detail -->
      <v-dialog v-model="dialog.mapdetail" transition="dialog-bottom-transition" max-width="890" scrollable persistent>
        <v-card>
          <v-toolbar dark color="primary" dense class="mb-2">
            <v-btn icon dark @click="Close('mapdetail')">
              <v-icon>{{$vars.V('icon_close')}}</v-icon>
            </v-btn>
            <v-toolbar-title>Map Detail - {{ form.mapheader_id }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="FieldToData" title="Reload"><v-icon color="white">refresh</v-icon></v-btn>
            <v-btn icon dark @click="AddMapDetail" title="Add New Map Detail"><v-icon color="white">add</v-icon></v-btn>
            <v-btn icon dark @click="Confirm(url_type, form.mapheader_id )" title="Save Map Detail"><v-icon color="white">save</v-icon></v-btn>
          </v-toolbar>
          <v-card-text>
            <v-progress-linear :indeterminate="true" v-if="loading.detaildialog"></v-progress-linear>
            <!-- Start Form Map Detail -->
            <v-form enctype="multipart/form-data" ref="ref_mapdetail_form" lazy-validation>
                <!-- Form Input -->
                <v-row class="mx-1 ma-1">
                  <v-col cols="12" sm="6" md="6">
                    <v-row class="mx-0">
                      <!-- Map Detail From Upload -->
                      <v-col cols="12" sm="7" md="7">
                        <template v-if="mapdetail.form.group.from.length > 0">
                          <v-combobox 
                          id            ="mapdetail_from" 
                          class         ="body-2" 
                          v-model       ="mapdetail.form.input.from" 
                          :rules        ="mapdetail.form.rules.from" 
                          :items        ="mapdetail.form.group.from" 
                          :readonly     ="mapdetail.form.readonly.from" 
                          @keyup.enter  ="Confirm('write','')" 
                          label         ="From" 
                          placeholder   ="From" 
                          hint          ="" 
                          required
                          ></v-combobox>
                        </template>
                        <template v-else>
                          <v-text-field 
                          id                ="mapdetail_fromfree"
                          v-model           ="mapdetail.form.input.fromfree"
                          @keyup.enter      ="Confirm('write','')"
                          label             ="From" 
                          placeholder       ="From" 
                          append-icon       ="radio_button_unchecked" 
                          @click:append     ="Dummy"
                          required 
                          ></v-text-field>
                      </template>
                      </v-col>
                      <!-- Map Detail From -->
                      <v-col cols="12" sm="5" md="5">
                        <v-text-field 
                        :label         ="mapdetail.form.input.label" 
                        @click        ="OpenUpload" 
                        append-icon  ="mdi-file-excel"
                        @click:append="ClearFile"
                        placeholder   ="Choose File Excel">
                        ></v-text-field>
                        <input 
                        style="display:none"
                        @change="DisplayFile($event)"
                        ref           ="fileupload" 
                        type          ="file" 
                        accept        ="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
                        />
                      </v-col>
                    </v-row>
                    <!-- Map Detail To -->
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                      id 		          ="mapdetailto"
                      class 			    ="body-2"
                      v-model 	  	  ="mapdetail.form.input.to"
                      :rules 	  		  ="mapdetail.form.rules.to"
                      :items          ="mapdetail.form.group.to" 
                      @keyup.enter 	  ="Confirm('write','')"
                      label       	  ="To"
                      placeholder 	  ="To"
                      hint            =""
                      persistent-hint
                      required 
                      ></v-combobox>
                    </v-col>
                    <!-- Map Detail Type -->
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                      class 			      ="body-2"
                      v-model 	  	    ="mapdetail.form.input.type"
                      :rules 	  		    ="mapdetail.form.rules.type"
                      :items            ="mapdetail.form.group.type"
                      @keyup.enter 	    ="Confirm('write','')"
                      label       	    ="Type"
                      placeholder 	    ="Type"
                      hint              =""
                      persistent-hint
                      required 
                      ></v-combobox>
                    </v-col>
                    <!-- Map Detail Default Value -->
                    <v-col cols=12 sm="12" md="12">
                      <v-text-field 
                      v-model           ="mapdetail.form.input.defaultvalue" 
                      :rules            ="mapdetail.form.rules.defaultvalue" 
                      @keyup.enter      ="Confirm('write','')"
                      label             ="Default Value" 
                      placeholder       ="Default Value" 
                      required 
                      ></v-text-field>
                    </v-col>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <!-- Map Detail Function -->
                    <v-col cols="12" sm="12" md="12" class="mt-n3">
                      <v-combobox
                      class 			      ="body-2"
                      v-model 	  	    ="mapdetail.form.input.func"
                      :rules 	  		    ="mapdetail.form.rules.func"
                      :items            ="mapdetail.form.group.func"
                      label       	    ="Function"
                      placeholder 	    ="Function"
                      hint              =""
                      @keyup.enter 	    ="Confirm('write','')"
                      persistent-hint
                      required 
                      ></v-combobox>
                    </v-col>
                    <v-row class="mx-0">
                      <!-- Map Detail Param 1 -->
                      <v-col cols=12 sm="6" md="6">
                        <v-text-field 
                        id              ="mapdetailparam_1" 
                        v-model         ="mapdetail.form.input.param_1" 
                        :rules          ="mapdetail.form.rules.param_1" 
                        :readonly       ="mapdetail.form.readonly.param_1" 
                        @keyup.enter    ="Confirm('write','')"
                        label           ="Param 1" 
                        placeholder     ="Param 1" 
                        required 
                        ></v-text-field>
                      </v-col>
                      <!-- Map Detail Param 2 -->
                      <v-col cols=12 sm="6" md="6">
                        <v-text-field 
                        id              ="mapdetailparam_2" 
                        v-model         ="mapdetail.form.input.param_2" 
                        :rules          ="mapdetail.form.rules.param_2" 
                        :readonly       ="mapdetail.form.readonly.param_2" 
                        @keyup.enter    ="Confirm('write','')"
                        label           ="Param 2" 
                        placeholder     ="Param 2" 
                        required 
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-0">
                      <!-- Compulsory -->
                      <v-col cols="12" sm="12" md="6">
                        <label>Compulsory :</label>
                        <v-checkbox v-model="mapdetail.form.input.compulsory" :label="mapdetail.form.input.compulsory === true ? 'Yes' : '' ">
                        </v-checkbox>
                      </v-col>
                      <!-- Allow Space -->
                      <v-col cols="12" sm="12" md="6">
                        <label>Allow Space :</label>
                        <v-checkbox v-model="mapdetail.form.input.allowspace" :label="mapdetail.form.input.allowspace === true ? 'Yes' : '' ">
                        </v-checkbox>
                      </v-col>
                      <!-- To Upper Case -->
                      <v-col cols="12" sm="12" md="6">
                        <label>To Upper Case :</label>
                        <v-checkbox v-model="mapdetail.form.input.touppercase" :label="mapdetail.form.input.touppercase === true ? 'Yes' : '' ">
                        </v-checkbox>
                      </v-col>
                      <!-- Map Value -->
                      <v-col cols="12" sm="12" md="6">
                        <label>Map Value :</label>
                        <v-checkbox v-model="mapdetail.form.input.mapvalue" :label="mapdetail.form.input.mapvalue === true ? 'Yes' : '' ">
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
            </v-form>
            <!-- End Form Map Detail -->
          </v-card-text>
        </v-card>
      </v-dialog>
    <!-- end dialog add map detail -->

    <!-- start dialog add map campaign -->
      <v-dialog v-model="dialog.mapcampaign" scrollable transition="dialog-bottom-transition" persistent max-width="890">
        <v-card>
          <v-toolbar dark color="primary" dense class="mb-2">
            <v-btn icon dark @click="Close('mapcampaign')">
              <v-icon>{{$vars.V('icon_close')}}</v-icon>
            </v-btn>
            <v-toolbar-title>Map Campaign - {{ form.mapheader_id }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="AddMapCampaign" title="Add New Map Campaign"><v-icon color="white">add</v-icon></v-btn>
            <v-btn icon dark @click="Confirm('addmapcampaign', form.mapheader_id)" title="Save Map Campaign"><v-icon color="white">save</v-icon></v-btn>
          </v-toolbar>
          <v-card-text>
            <v-progress-linear :indeterminate="true" v-if="loading.campaigndialog"></v-progress-linear>
            <!-- Start Form Map Campaign -->
            <v-form enctype="multipart/form-data" ref="ref_mapcampaign_form" lazy-validation>
              <v-row class="mx-2 mt-2">
                <!-- Form Input -->
                <v-col cols="12" sm="12" md="12" class="mb-n5 mt-n5">
                  <v-row class="mx-2">
                    <v-col cols="12" sm="12" md="12">
                      <!-- Map Campaign -->
                      <v-col cols="12" sm="12" md="12">
                        <app-cb
                        cb_type           	="campaign"
                        cb_url            	="apiListCampaign"
                        cb_url_parameter  	="campaig_actived=Y"
                        cb_title          	="Campaign"
                        cb_id             	="mapcampaign_campaignid"
                        cb_desc           	="campaign_campaigndesc"
                        cb_rules          	="Please fill Campaign"
                        cb_desc_readonly  	="readonly"
                        cb_items_id       	="campaign_id"
                        cb_items_desc     	="campaign_desc"
                        :cb_value_id		    ="form.campaign_id"
                        :cb_value_desc		  ="form.campaign_desc"
                        >
                        </app-cb>
                      </v-col>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">

                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    <!-- end dialog add map campaign -->

    <!-- start dialog add map value -->
      <v-dialog v-model="dialog.mapvalue" fullscreen hide-overlay transition="dialog-bottom-transition" max-width="290">
        <v-card>
          <v-toolbar dark color="primary" dense>
            <v-btn icon dark @click="Close('mapvalue')">
              <v-icon>{{$vars.V('icon_close')}}</v-icon>
            </v-btn>
            <v-toolbar-title>Map Value - {{ form.mapheader_id }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="AddMapValue" title="Add New Map Value"><v-icon color="white">add</v-icon></v-btn>
            <v-btn icon dark @click="Confirm('addmapvalue', form.mapheader_id)" title="Save Map Value"><v-icon color="white">save</v-icon></v-btn>
          </v-toolbar>
          <v-card-text>
            <v-progress-linear :indeterminate="true" v-if="loading.valuedialog"></v-progress-linear>
            <!-- Start Form Map Value -->
            <v-form enctype="multipart/form-data" ref="ref_mapvalue_form" lazy-validation>
              <v-row class="mx-0">
                <!-- Form Input -->
                <v-col cols="12" sm="6" md="6">
                  <v-text-field  
                  id            ="mapvalue_fieldfrom" 
                  class         ="body-2" 
                  v-model       ="mapvalue.form.input.fieldfrom" 
                  @keyup.enter  ="Confirm('write','')" 
                  label         ="Field From" 
                  placeholder   ="Field From" 
                  hint          ="" 
                  required
                  readonly
                  ></v-text-field >
                  <v-text-field 
                  id              ="mapvalue_from" 
                  v-model         ="mapvalue.form.input.from" 
                  :rules          ="mapvalue.form.rules.from" 
                  :readonly       ="mapvalue.form.readonly.from" 
                  @keyup.enter    ="Confirm('write','')"
                  label           ="From" 
                  placeholder     ="From" 
                  required 
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field  
                  id            ="mapvalue_fieldto" 
                  class         ="body-2" 
                  v-model       ="mapvalue.form.input.fieldto" 
                  @keyup.enter  ="Confirm('write','')" 
                  label         ="Field To" 
                  placeholder   ="Field To" 
                  hint          ="" 
                  required
                  readonly
                  ></v-text-field >
                  <v-text-field 
                  v-model         ="mapvalue.form.input.to" 
                  :rules          ="mapvalue.form.rules.to" 
                  :readonly       ="mapvalue.form.readonly.to" 
                  @keyup.enter    ="Confirm('write','')"
                  label           ="To" 
                  placeholder     ="To" 
                  required 
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <!-- End Form Map Value -->
            <v-spacer></v-spacer>
            <!-- Menu Table Map Value -->
            <v-col cols="12" sm="12" md="12">
              <v-btn :color="mapvalue.table.selected.length === mapvalue.table.items.length ? 'fourth':''" @click="SelectAllMapValue" title="select all" class="mr-2" small>
                select all
              </v-btn>
              <v-btn @click="AddMapValue" class="mr-2" title="select all" small>
                Add
              </v-btn>
              <v-btn color="error" title="delete selected" @click="Confirm('multideletemapvalue','')" class="mr-2" v-if="mapvalue.table.selected.length > 0" :disabled="access.delete === 0 ? true:false" small>
                <v-icon>{{$vars.V('icon_delete')}}</v-icon>
              </v-btn>
              <v-btn color="primary" title="search" v-if="mapvalue.table.search_button === false" @click="SearchMapValue" small>
                <v-icon>{{$vars.V('icon_search')}}</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field v-model="mapvalue.table.search" append-icon="search" @click:append="mapvalue.table.search_button = false" label="Type and Enter" single-line hide-values v-if="mapvalue.table.search_button" id="mapvalue.table.search" clearable @keyup.enter="ListMapValue(form.mapheader_id, mapdetail.form.input.fieldfrom, mapdetail.form.input.fieldto)"></v-text-field>
              <!-- Start Table Map Value -->
              <v-data-table
                v-model="mapvalue.table.selected"
                :headers="mapvalue.table.data"
                :items="mapvalue.table.items"
                class="elevation-1 mt-2"
                item-key="mapdvalue_headerid"
                show-select
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'first_page',
                  lastIcon: 'last_page',
                  prevIcon: 'keyboard_arrow_left',
                  nextIcon: 'keyboard_arrow_right'
                }"
                dense
                :loading  ="loading.list"
                loading-text="Loading... Please wait"
              >
                <template v-slot:item.action="{ item }">
                  <!-- <v-icon small class="mr-2" @click="EditValue(item.mapvalue_headerid, item.mapvalue_fieldfrom, item.mapvalue_fieldto, item.mapvalue_from, item.mapvalue_to)" :disabled ="access.edit === 0 ? true:false">
                    edit
                  </v-icon> -->
                  <v-icon small @click="Confirm('deletemapvalue',item.mapvalue_headerid, item.mapvalue_fieldfrom, item.mapvalue_fieldto, item.mapvalue_from, item.mapvalue_to)" :disabled="access.delete === 0 ? true:false">
                    delete
                  </v-icon>
                </template>
              </v-data-table>
              <!-- End Table Map Value -->
            </v-col>
            
          </v-card-text>
        </v-card>
      </v-dialog>
    <!-- end dialog add map value -->

    <!-- Start Dialog General -->
    <v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
      <v-card>
        <v-toolbar dark color="fourth" dense>
        <v-btn icon dark @click="remarks.dialog = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.dialog" :timeout="snackbar.timeout" color="fourth" rounded="pill" top style="z-index: 9999">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
        <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
        <v-card :color="Variable('confirm',4)">
            <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
            <v-card-text>
                
            </v-card-text>
            <v-card-actions>
            	<v-spacer></v-spacer>
            	<v-btn id="dialog" :color="Variable('confirm',1)"
            		   :disabled   = "confirm.text === 'Ok' ? false:true"
      				   @click      = "Loading">{{confirm.text}}</v-btn>
                <v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- End Dialog General -->
    <!-- Display Show Info Users -->
    <app-users :visible="dialog.users" :users_id="form.display_users" @close="dialog.users=false"></app-users>
    <!-- Display Show Info Users -->
  </div>
</template>
<script>
import XLSX from 'xlsx'
export default {
  data: () => ({
    // ========================================================================
    // Start General
    // ========================================================================
      url_type: 'add',
      cont: false,
      access: {
        read: 0,
        add: 0,
        edit: 0,
        delete: 0,
      },
      snackbar: {
        dialog: false,
        color: "primary",
        text: "",
        timeout: 3000,
      },
      confirm: {
        dialog: false,
        title: '',
        text: 'Ok',
        disabled: false,
      },
      singleselect: false,
      selected: [],
      search: '',
      remarks: {
        feedback: "",
        dialog: false,
      },
      loading: {
        page : true,
        list : false,
        mapdetail : false,
        subclass: false,
        field: false,
        mapcampaign: false,
        mapvalue: false
      },
      flag: {
        header: false,
        mapdetail: false,
        mapcampaign: false,
        mapvalue: false,
        showdetail: false,
      },
      dialog: {
        attributes: false,
        mapdetail: false,
        mapcampaign: false,
        mapvalue: false,
        users: false,
      },
    // ========================================================================
    // End General
    // ========================================================================

    // ========================================================================
    // Start Map Header
    // ========================================================================
      // Table
      mapHeaders: [
        { text: 'Actions', value: 'action', sortable: false },
        { text: 'ID', align: 'left', sortable: false, value: 'mapheader_id' },
        { text: 'Description', value: 'mapheader_desc' },
        { text: 'Type', value: 'mapheader_type' },
        { text: 'Partial', value: 'mapheader_partial' },
        { text: 'Create Profile', value: 'mapheader_createprofile' },
        { text: 'Duplicate Ref No', value: 'mapheader_duplicaterefno' },
        { text: 'Actived', value: 'mapheader_actived' },
      ],
      mapHeadersItems: [],
      
      singleselect_mapupload: false,
      selected_mapupload: [],
      search_mapupload: '',
      items_mapupload: [],

      mapheader_detaildesc_value: {},
      mapheader_type:[
        'UP',
        'SC',
        'CL',
        'BC',
        'BE',
      ],
      // Form
      form: {
        mapheader_actived: true,
        mapheader_id_readonly: true,
        mapheader_id_rules: [v => !!v || 'Please fill ID'],
        mapheader_desc: '',
        mapheader_desc_rules: [v => !!v || 'Please fill Description'],
        mapheader_fieldtype: 0,
        mapheader_compulsoryf: 0,
        mapheader_selectf: 0,
        mapheader_type:'',
        mapheader_partial: false,
        mapheader_createprofile: false,
        mapheader_duplicaterefno: false,
        mapheader_reference: false,
        mapheader_allowed: false,
        display_users: ''
      },
    // ========================================================================
    // End Map Header
    // ========================================================================
    
    // ========================================================================
    // Start Map Detail
    // ========================================================================
      mapdetail: {
        table: {
          data: [
            { text: 'Actions', value: 'action', sortable: false, class: "fourth"  },
            { text: 'From', value: 'mapdetail_from', align: 'start', class: "fourth" },
            { text: 'To', value: 'mapdetail_to', class: "fourth"  },
            { text: 'Type', value: 'mapdetail_type', class: "fourth"  },
            { text: 'Default', value: 'mapdetail_defaultvalue', class: "fourth"  },
            { text: 'Function', value: 'mapdetail_func', class: "fourth"  },
            { text: 'Param 1', value: 'mapdetail_param_1', class: "fourth"  },
            { text: 'Param 2', value: 'mapdetail_param_2', class: "fourth"  },
            { text: 'Compulsory', value: 'mapdetail_compulsory', class: "fourth"  },
            { text: 'Space', value: 'mapdetail_allowspace', class: "fourth"  },
            { text: 'To Upper', value: 'mapdetail_touppercase', class: "fourth"  },
            { text: 'Map Value', value: 'mapdetail_mapvalue', class: "fourth"  },
          ],
          items: [],
          single_select: false,
          selected: [],
          search: '',
          search_button: false,
        },
        form: {
          input: {
            fromfree: '',
            from: [],
            to: [],
            type: [],
            defaultvalue: '',
            func: [],
            param_1: 0,
            param_2: 0,
            compulsory: false,
            allowspace: false,
            touppercase: false,
            mapvalue: false,
            label: 'XLSX File'
          },
          group: {
            from: [],
            to: [],
            type: ['N','D','S'],
            func: ['Left','Right','Mid'],
          },
          rules: {

          },
          readonly: {
            from: false,
            param_1: false,
            param_2: false,
          },
        },
      },
      searchmapdetail: '',
    // ========================================================================
    // End Map Detail
    // ========================================================================

    // ========================================================================
    // Start Map Campaign
    // ========================================================================
      mapcampaign: {
        table: {
          data: [
            { text: 'Header ID', value: 'mapcampaign_headerid', sortable: false },
            { text: 'Campaign ID', value: 'mapcampaign_campaignid', align: 'start', sortable: false },
            { text: 'Campaign Description', value: 'mapcampaign_campaigndesc', align: 'start', sortable: false },
            { text: 'Sub Class', value: 'mapcampaign_subclass', align: 'start', sortable: false },
            { text: 'Actions', value: 'action', sortable: false }
          ],
          items: [],
          single_select: false,
          selected: [],
          search: '',
          search_button: false,
        },
        form: {
          input: {
            headerid: '',
            campaignid: '',
          },
          group: {

          },
          rules: {

          },
        },
      },
    // ========================================================================
    // End Map Campaign
    // ========================================================================

    // ========================================================================
    // Start Map Value
    // ========================================================================
      mapvalue: {
        table: {
          data: [
            { text: 'ID', align: 'left', value: 'mapvalue_headerid', sortable: false },
            { text: 'Field From', value: 'mapvalue_fieldfrom' },
            { text: 'Field To', value: 'mapvalue_fieldto' },
            { text: 'From', value: 'mapvalue_from' },
            { text: 'To', value: 'mapvalue_to' },
            { text: 'Actions', value: 'action', sortable: false }
          ],
          items: [],
          single_select: false,
          selected: [],
          search: '',
          search_button: false,
        },
        form: {
          input: {
            headerid: '',
            fieldfrom: '',
            from: '',
            fromold: '',
            toold: '',
            fieldto: '',
            to: '',
          },
          group: {
            
          },
          rules: {

          },
          readonly: {
            
          },
        },
      },
    // ========================================================================
    // End Map Value
    // ========================================================================

		selected_segment: [],
		items_segment: [],
    btn_search: true,
    attributes: false,
  }),
  created () {
    this.FirstLoad()
  },
  computed: {
    general_detail () {
          for (let i = 0 ;i <= this.items_mapupload.length - 1 ;i++) {
              const ano = this.items_mapupload[i]
              ano.id = ano.gendet_id + '|' + ano.gendet_mapheader_id
          }
          return this.items_mapupload
      },
      selectall_segment: {
          get: function () {
              return this.items_segment ? this.selected_segment.length == this.items_segment.length : false;
          },
          set: function (value) {
              var selected = [];
              if (value) {
                  this.items_segment.forEach(function (data) {
                      selected.push(data.segment_id);
                  });
              }
              this.selected_segment = selected;
          }
      }
  },
  methods: {
    // ========================================================================
    // Start Function General
    // ========================================================================
      FirstLoad () {
        //this function use for validating the screen with user privilleges
        this.Access()
      },
      //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
      Access () {
        let modul ="mapupload"
        let formdata = {
          menu_id: modul,
          users_id: this.$functions.UsersID(),
          order_by: 'menu_id',
          order_type: 'ASC',
          limit: this.limit,
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListMenuUser'),param,{
          headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let priv = response.data
          if (priv.length > 0) {
            if (priv[0].feedback !== 'Y') {
              this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true
              this.remarks.feedback = priv[0].feedback
            } else {
              this.access.read  = priv[0].usersmenu_read
              this.access.add   = priv[0].usersmenu_add
              this.access.edit    = priv[0].usersmenu_edit
              this.access.delete  = priv[0].usersmenu_delete
              if (priv[0].usersmenu_read === 0) {
                this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
              }
            }
          } else {
            this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
          }
          this.loading.page = false
        })
        .catch(e => {
          this.SnackBar(true, 'error', e, 3000)
          this.loading.list = false
        })
      },
      SelectAllPage () {
        this.selected = this.selected === this.mapHeadersItems ? [] : this.mapHeadersItems
      },
      Check (value) {
        this.selected = value
      },
      SelectAllDetail() {
        this.detail.selected = this.detail.selected === this.detail.items ? [] : this.detail.items
      },
      CheckDetail(value) {
        this.detail.selected = value
      },
      //Pop Up message for validating (Ok or Cancel) your proccess
      Confirm (flag, id, secid, thrid, forid, fifid) {
        if (flag === "write" || flag ==="add" || flag === "edit") {
          if (this.$refs.form.validate()) {
            this.confirm.dialog = true
            this.confirm.title ="Save"
            setTimeout(function() {
              document.getElementById("dialog").focus()
            }, 500)
          }
        } else if (flag ==="delete") {
          this.url_type ="delete"
          this.confirm.dialog = true
          this.confirm.title ="Delete `" + id + "`"
          this.form.mapheader_id = id
          setTimeout(function() {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag ==="multidelete") {
          this.url_type ="multidelete"
          this.confirm.dialog = true
          this.confirm.title =
            "Delete `" + this.selected.length + "` data"
          setTimeout(function() {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag === 'addmapdetail') {
          if (this.$refs.ref_mapdetail_form.validate()) {
            let froms = this.mapdetail.form.input.from
            let defaultvalue = this.mapdetail.form.input.defaultvalue
            let func = this.mapdetail.form.input.func
            let param1 = this.mapdetail.form.input.param_1
            let param2 = this.mapdetail.form.input.param_2
            let errors = ''
            if (froms === 'DATADUMMY' && defaultvalue ==='') {
              errors = 'Please fill Default Value if you choose `DATADUMMY`'
            } 
            if (func !== '' && (param1 === '' || param2 === '')) {
              errors = 'Please input param 1 or param 2'
            }
            if (errors !== '') {
              this.SnackBar(true, "error", errors, 3000)
            } else {
              this.url_type 		      = 'addmapdetail'
              this.confirm.dialog     = true
              this.confirm.title      ="Save Detail '" + id + "`"
              setTimeout(function(){
                document.getElementById('dialog').focus()
              }, 500)
            }
          }
        } else if (flag === 'editmapdetail') {
          if (this.$refs.ref_mapdetail_form.validate()) {
            this.url_type 		  = 'editmapdetail'
            this.confirm.dialog   = true
            this.confirm.title    = 'Edit Detail'
            setTimeout(function(){
              document.getElementById('dialog').focus()
            }, 500)
          }
        } else if (flag === 'deletemapdetail') {
          this.url_type 		        = 'deletemapdetail'
          this.confirm.dialog       = true
          this.confirm.title        = 'Delete Detail `' + id + '`'
          this.mapdetail_from       = secid
          setTimeout(function(){
                document.getElementById('dialog').focus()
              }, 500)
        } else if (flag === 'multideletemapdetail') {
          this.url_type 		    = 'multideletemapdetail'
          this.confirm.dialog   = true
          this.confirm.title    = 'Delete `'+ this.mapdetail.table.selected.length + '` data'
          setTimeout(function(){
                document.getElementById('dialog').focus()
              }, 500)
        } else if (flag === 'addmapcampaign') {
          if (this.$refs.ref_mapcampaign_form.validate()) {
            this.url_type 		        = 'addmapcampaign'
            this.mapcampaign_headerid = id
            this.confirm.dialog       = true
            this.confirm.title        ="Save Campaign '" + id + "`"
            setTimeout(function(){
              document.getElementById('dialog').focus()
            }, 500)
          }
        } else if (flag === 'editmapcampaign') {
          if (this.$refs.form_campaign.validate()) {
            this.url_type 		      = 'editmapcampaign'
            this.mapdetail_headerid = id
            this.confirm.dialog     = true
            this.confirm.title      ="Edit Campaign '" + id + "`"
            setTimeout(function(){
              document.getElementById('dialog').focus()
            }, 500)
          }
        } else if (flag === 'deletemapcampaign') {
          this.url_type 		          = 'deletemapcampaign'
          this.confirm.dialog         = true
          this.confirm.title          = 'Delete Campaign `' + secid + '`'
          this.mapcampaign_headerid   = id
          this.mapcampaign_campaignid = secid
          setTimeout(function(){
                document.getElementById('dialog').focus()
              }, 500)
        } else if (flag === 'multideletecampaign') {
          this.url_type 		  = 'multideletecampaign'
          this.confirm.dialog   = true
          this.confirm.title    = 'Delete `'+ this.campaign.selected.length + '` data'
          setTimeout(function(){
                document.getElementById('dialog').focus()
              }, 500)
        } else if (flag === 'addmapvalue') {
          if (this.$refs.ref_mapvalue_form.validate()) {
            this.url_type 		        = 'addmapvalue'
            this.mapvalue_headerid    = id
            this.confirm.dialog       = true
            this.confirm.title        ="Save Value"
            setTimeout(function(){
              document.getElementById('dialog').focus()
            }, 500)
          }
        } else if (flag === 'editmapvalue') {
          if (this.$refs.form_value.validate()) {
            this.url_type 		      = 'editmapvalue'
            this.mapdetail_headerid = id
            this.confirm.dialog     = true
            this.confirm.title      ="Edit Campaign '" + id + "`"
            setTimeout(function(){
              document.getElementById('dialog').focus()
            }, 500)
          }
        } else if (flag === 'deletemapvalue') {
          this.url_type 		      = 'deletemapvalue'
          this.confirm.dialog     = true
          this.confirm.title      = 'Delete Map Value `' + forid + '` `' + fifid + '`'
          this.mapvalue_headerid  = id
          this.mapvalue.form.input.fieldfrom = secid
          this.mapvalue.form.input.fieldto   = thrid
          this.mapvalue.form.input.from      = forid
          this.mapvalue.form.input.to        = fifid
          setTimeout(function(){
                document.getElementById('dialog').focus()
              }, 500)
        } else if (flag === 'multideletevalue') {
          this.url_type 		  = 'multideletevalue'
          this.confirm.dialog   = true
          this.confirm.title    = 'Delete `'+ this.value.selected.length + '` data'
          setTimeout(function(){
                document.getElementById('dialog').focus()
              }, 500)
        }
      },
      SnackBar (dialog, color, text, timeout) {
        this.snackbar = {
          dialog: dialog,
          color: color,
          text: text,
          timeout: timeout
        }
        if (color === 'error') {
          this.confirm.dialog 	 = false
          this.confirm.text 	 	 = 'Ok'
        }
      },
      Dialog (flag) {
        if (flag === 'AT') {
          this.dialog.attributes = true
          this.AttributesValue()
        } else if (flag === 'SC') {
          this.dialog.subclass = true
          this.SubClassValue()
        } else if (flag === 'SG') {
          this.dialog.segment = true
          this.SegmentValue()
        }
      },
      GetData (value) {
        if (value !== undefined) {
          let splits = this.$functions.Split(value, '|')
          // document.getElementById('mapheader_fieldlabel_' + splits[2]).value = splits[1]
          this.$set(this.mapheader_fieldlabel, splits[2],splits[1])
        }
      },
      Close (flag) {
        if (flag === 'mapdetail') {
          this.url_type = 'edit'
          this.dialog.mapdetail = false
        } else if (flag === 'mapcampaign') {
          this.url_type = 'edit'
          this.dialog.mapcampaign = false
        } else if (flag === 'confirm') {
          this.confirm.dialog = false
          if (this.url_type !== 'add') {
            this.url_type = 'edit'
          }
        } else if (flag === 'mapvalue') {
          this.url_type = 'edit'
          this.dialog.mapvalue = false
        }
      },
      // Loading
      Loading () {
        this.confirm.text 	 = 'Loading...'
        if (this.url_type === 'add' || this.url_type === 'edit') {
          this.Write()
        } else if (this.url_type === 'delete') {
          this.Delete(this.form.mapheader_id)
        } else if (this.url_type === 'multidelete') {
          this.MultiProcess('multidelete')
        } else if (this.url_type === 'addmapdetail' || this.url_type === 'editmapdetail') {
          this.WriteMapDetail(this.form.mapheader_id)
        } else if (this.url_type === 'deletemapdetail') {
          this.DeleteMapDetail(this.form.mapheader_id, this.mapdetail_from)
        } else if (this.url_type === 'multideletemapdetail') {
          this.MultiProcessMapDetail('multideletemapdetail')
        } else if (this.url_type === 'addmapcampaign' || this.url_type === 'editmapcampaign') {
          this.WriteMapCampaign()
        } else if (this.url_type === 'deletemapcampaign') {
          this.DeleteMapCampaign(this.mapcampaign_headerid, this.mapcampaign_campaignid)
        } else if (this.url_type === 'multideletemapcampaign') {
          this.MultiProcessMapCampaign('multideletemapcampaign')
        } else if (this.url_type === 'addmapvalue' || this.url_type === 'editmapvalue') {
          this.WriteMapValue()
        } else if (this.url_type === 'deletemapvalue') {
          this.DeleteMapValue(this.mapvalue_headerid, this.mapvalue_fieldfrom, this.mapvalue_fieldto, this.mapvalue_from, this.mapvalue_to)
        } else if (this.url_type === 'multideletemapvalue') {
          this.MultiProcessMapValue('multideletemapvalue')
        }
      },
    // ========================================================================
    // End Function General
    // ========================================================================
    // ========================================================================
    // Start Function Map Value
    // ========================================================================
      ListMapValue (id, fieldfrom, fieldto) {
        this.mapvalue.table.items = []
        this.loading.value = true
        let formdata = {
          mapvalue_headerid: id,
          mapvalue_fieldfrom: fieldfrom,
          mapvalue_fieldto: fieldto,
					datavalue: this.mapvalue.table.search,
          order_type: 'ASC',
          limit: '100',
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListMapValue'),param,{
          headers: {
              'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let feedback = response.data
            if (feedback.length > 0) {
              if (feedback[0].feedback !=="Y") {
                this.SnackBar(true, "error", feedback[0].feedback, 0)
              } else {
                this.mapvalue.table.items = feedback
              }
            }
            this.loading.value = false
        })
        .catch(e => {
          this.SnackBar(true, 'error', e, 3000)
          this.loading.value = false
        })
      },

      SelectAllMapValue() {
        this.mapvalue.table.selected = this.mapvalue.table.selected === this.mapvalue.table.items ? [] : this.mapvalue.table.items
      },

      SearchMapValue () {
	  		this.mapvalue.table.search_button = true
	  		setTimeout(function() {
				  document.getElementById("mapvalue.table.search").focus()
			  }, 500)
	  	},

      OpenMapValue (id, fieldfrom, fieldto) {
        this.dialog.mapvalue = true
        this.mapvalue.form.input.fieldfrom = fieldfrom
        this.mapvalue.form.input.fieldto = fieldto
        this.ListMapValue(id, fieldfrom, fieldto)
        this.AddMapValue()
      },

      AddMapValue () {
        this.url_type  = 'addmapvalue'
        this.mapvalue.form.input.from = ''
        this.mapvalue.form.input.to = ''
        setTimeout(function(){
          document.getElementById('mapvalue_from').focus()
        }, 500)
      },

      WriteMapValue () {
        let formdata = {
            url_type: this.url_type,
            map_type: 'value',
            mapvalue_headerid:     this.form.mapheader_id,
            mapvalue_fieldfrom:     this.mapvalue.form.input.fieldfrom,
            mapvalue_fieldto:     this.mapvalue.form.input.fieldto,
            mapvalue_from:     this.mapvalue.form.input.from,
            mapvalue_to:     this.mapvalue.form.input.to,
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios
          .post(this.$functions.UrlPOST('apiWriteMapValue'), param,{
          headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let feedback = response.data
          if (feedback[0].feedback === 'Y') {
              this.SnackBar(true, 'success', 'Save Success', 3000)
              this.confirm.dialog  	 = false
              this.confirm.text 	 	 = 'Ok'
              if (this.url_type ==="addmapvalue") {
                this.form.mapvalue_cuser = feedback[0].feedback_mapvalue_id
                this.form.mapvalue_cdate = feedback[0].feedback_mapvalue_date
                this.url_type 			 = 'editmapvalue'
              }
              this.form.mapvalue_luser = feedback[0].feedback_mapvalue_id
              this.form.mapvalue_ldate = feedback[0].feedback_mapvalue_date
              this.ListMapValue(this.mapvalue_headerid, this.mapvalue.form.input.fieldfrom, this.mapvalue.form.input.fieldto)
          } else {
              this.SnackBar(true, 'error', feedback[0].feedback, 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
          }
        })
        .catch(e => {
          this.SnackBar(true, 'error', 'Check Error Message', 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
      },

      DeleteMapValue (id, fieldfrom, fieldto, from, to) {
        let formdata = {
          url_type: this.url_type,
          mapvalue_headerid: id,
          mapvalue_fieldfrom: fieldfrom,
          mapvalue_fieldto: fieldto,
          mapvalue_from: from,
          mapvalue_to: to
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiWriteMapValue'), param,{
          headers: {
              'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === 'Y') {
              this.SnackBar(true, 'success', 'Delete Success', 3000)
              this.confirm.dialog  	 = false
              this.confirm.text 	 = 'Ok'
              this.selected = []
              this.ListMapValue(id, fieldfrom, fieldto)
            } else {
              this.SnackBar(true, 'error', feedback[0].feedback, 0)
              this.remarks.dialog 	= true
              this.remarks.feedback 	= feedback[0].feedback
            }
          }
          
        })
        .catch(e => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog 	= true
          this.remarks.feedback 	= e
        })
      },
    // ========================================================================
    // End Function Map Value
    // ========================================================================
    // ========================================================================
    // Start Function Map Campaign
    // ========================================================================
      ListMapCampaign (id) {
        this.mapcampaign.table.items = []
        this.loading.campaign = true
        let formdata = {
          mapcampaign_headerid: id,
					datavalue: this.mapcampaign.table.search,
          order_type: 'ASC',
          limit: '100',
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListMapCampaign'),param,{
          headers: {
              'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let feedback = response.data
            if (feedback.length > 0) {
              if (feedback[0].feedback !=="Y") {
                this.SnackBar(true, "error", feedback[0].feedback, 0)
              } else {
                this.mapcampaign.table.items = feedback
              }
            }
            this.loading.campaign = false
        })
        .catch(e => {
          this.SnackBar(true, 'error', e, 3000)
          this.loading.campaign = false
        })
      },

      SelectAllMapCampaign() {
        this.mapcampaign.table.selected = this.mapcampaign.table.selected === this.mapcampaign.table.items ? [] : this.mapcampaign.table.items
      },

      SearchMapCampaign () {
	  		this.mapcampaign.table.search_button = true
	  		setTimeout(function() {
				  document.getElementById("mapcampaign.table.search").focus()
			  }, 500)
	  	},

      OpenMapCampaign () {
        this.dialog.mapcampaign = true
        this.AddMapCampaign()
      },

      AddMapCampaign () {
        this.url_type  = this.url_type
        this.form.campaign_id = ''
        this.form.campaign_desc = ''
        setTimeout(function(){
          document.getElementById('mapcampaign_campaignid').focus()
        }, 500)
      },

      WriteMapCampaign () {
        let formdata = {
            url_type: this.url_type,
            map_type: 'campaign',
            mapcampaign_headerid:     this.mapcampaign_headerid,
            mapcampaign_campaignid:   document.getElementById('mapcampaign_campaignid').value,
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios
          .post(this.$functions.UrlPOST('apiWriteMapCampaign'), param,{
          headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let feedback = response.data
          if (feedback[0].feedback === 'Y') {
              this.SnackBar(true, 'success', 'Save Success', 3000)
              this.confirm.dialog  	 = false
              this.confirm.text 	 	 = 'Ok'
              if (this.url_type ==="add") {
                this.form.mapcampaign_cuser = feedback[0].feedback_mapcampaign_id
                this.form.mapcampaign_cdate = feedback[0].feedback_mapcampaign_date
              }
              this.form.mapcampaign_luser = feedback[0].feedback_mapcampaign_id
              this.form.mapcampaign_ldate = feedback[0].feedback_mapcampaign_date
              this.ListMapCampaign(this.mapcampaign_headerid)
              this.url_type 			 = 'edit'
          } else {
              this.SnackBar(true, 'error', feedback[0].feedback, 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
          }
        })
        .catch(e => {
          this.SnackBar(true, 'error', 'Check Error Message', 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
      },

      DeleteMapCampaign (id, campaignid) {
        let formdata = {
          url_type: this.url_type,
          mapcampaign_headerid: id,
          mapcampaign_campaignid: campaignid
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiWriteMapCampaign'), param,{
          headers: {
              'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === 'Y') {
              this.SnackBar(true, 'success', 'Delete Success', 3000)
              this.confirm.dialog  	 = false
              this.confirm.text 	 = 'Ok'
              this.selected = []
              this.ListMapCampaign(id)
            } else {
              this.SnackBar(true, 'error', feedback[0].feedback, 0)
              this.remarks.dialog 	= true
              this.remarks.feedback 	= feedback[0].feedback
            }
          }
          
        })
        .catch(e => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog 	= true
          this.remarks.feedback 	= e
        })
      },
    // ========================================================================
    // End Function Map Campaign
    // ========================================================================
    // ========================================================================
    // Start Function Map Detail
    // ========================================================================
      ListMapDetail (id) {
        this.mapdetail.table.items = []
        this.loading.detail = true
        let formdata = {
          mapdetail_headerid: id,
					datavalue: this.mapdetail.table.search,
          order_by: 'mapdetail_from',
          order_type: 'ASC',
          limit: '100',
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListMapDetail'),param,{
          headers: {
              'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let feedback = response.data
            if (feedback.length > 0) {
              if (feedback[0].feedback !=="Y") {
                this.SnackBar(true, "error", feedback[0].feedback, 0)
              } else {
                this.mapdetail.table.items = feedback
              }
            }
            this.loading.detail = false
        })
        .catch(e => {
          this.SnackBar(true, 'error', e, 3000)
          this.loading.detail = false
        })
      },
      
      SelectAllMapDetail() {
        this.mapdetail.table.selected = this.mapdetail.table.selected === this.mapdetail.table.items ? [] : this.mapdetail.table.items
      },

      CheckMapDetListMapDetail(value) {
        this.mapdetail.table.selected = value
      },

      SearchMapDetail () {
	  		this.mapdetail.table.search_button = true
	  		setTimeout(function() {
				  document.getElementById("searchmapdetail").focus()
			  }, 500)
	  	},

      OpenMapDetail () {
        this.dialog.mapdetail = true
        this.AddMapDetail()
      },

      AddMapDetail () {
        this.url_type      	  	 				          = 'addmapdetail'
        this.mapdetail.form.input.from 				    = ''
        this.mapdetail.form.input.fromfree		    = ''
        this.mapdetail.form.input.type_group  		= ''
        this.mapdetail.form.input.defaultvalue 	  = ''
        this.mapdetail.form.input.func            = ''
        this.mapdetail.form.input.param_1   		  = 0
        this.mapdetail.form.input.param_2 		    = 0
        this.mapdetail.form.input.compulsory 		  = false
        this.mapdetail.form.input.allowspace 		  = false
        this.mapdetail.form.input.touppercase 		= false
        this.mapdetail.form.input.mapvalue 			  = false
        this.mapdetail.form.input.to 			        = ''
        this.mapdetail.form.readonly.from         = false
        this.FieldToData()
        setTimeout(function(){
          document.getElementById('mapdetailto').focus()
        }, 500)
      },

      EditMapDetail (headerid, from, to, type, defaultvalue, func, param_1, param_2, compulsory, allowspace, touppercase, mvalue) {
        this.url_type 			    	              = 'editmapdetail'
        this.mapdetail.form.input.headerid      = headerid
        this.mapdetail.form.input.fromold       = from
        this.mapdetail.form.input.from 	        = ''
        this.mapdetail.form.input.fromfree      = from
        this.mapdetail.form.input.to 	          = to
        this.mapdetail.form.input.toold         = to
        this.mapdetail.form.input.type 	        = type
        this.mapdetail.form.input.defaultvalue  = defaultvalue
        this.mapdetail.form.input.func 	        = func
        this.mapdetail.form.input.param_1 	    = param_1
        this.mapdetail.form.input.param_2 	    = param_2
        this.mapdetail.form.input.compulsory    = this.$functions.TrueOrFalse(compulsory)
        this.mapdetail.form.input.allowspace    = this.$functions.TrueOrFalse(allowspace)
        this.mapdetail.form.input.touppercase   = this.$functions.TrueOrFalse(touppercase)
        this.mapdetail.form.input.mapvalue      = this.$functions.TrueOrFalse(mvalue)
        this.mapdetail.form.readonly.from       = true
        this.flag.mapdetail      	 		          = true
        this.dialog.mapdetail       		        = true
        this.FieldToData()
        setTimeout(function(){
          document.getElementById('mapdetail_from').focus()
        }, 500)
      },

      WriteMapDetail () {
        let from  = this.mapdetail.form.input.from
        if (this.mapdetail.form.input.from === '' && this.mapdetail.form.input.fromfree !== '')  {
          from = this.mapdetail.form.input.fromfree
        }
        let formdata = {
            url_type: this.url_type,
            map_type: 'detail',
            mapdetail_headerid:     this.form.mapheader_id,
            mapdetail_from_old:     this.mapdetail.form.input.fromold,
            mapdetail_to_old:     this.mapdetail.form.input.toold,
            mapdetail_from:         from,
            mapdetail_to:           this.mapdetail.form.input.to,
            mapdetail_type:         this.mapdetail.form.input.type,
            mapdetail_defaultvalue: this.mapdetail.form.input.defaultvalue,
            mapdetail_func:         this.mapdetail.form.input.func,
            mapdetail_param_1:      String(this.mapdetail.form.input.param_1),
            mapdetail_param_2:      String(this.mapdetail.form.input.param_2),
            mapdetail_compulsory:   this.$functions.ActivedFlag(this.mapdetail.form.input.compulsory),
            mapdetail_allowspace:   this.$functions.ActivedFlag(this.mapdetail.form.input.allowspace),
            mapdetail_touppercase:  this.$functions.ActivedFlag(this.mapdetail.form.input.touppercase),
            mapdetail_mapvalue:     this.$functions.ActivedFlag(this.mapdetail.form.input.mapvalue),
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios
          .post(this.$functions.UrlPOST('apiWriteMapDetail'), param,{
          headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let feedback = response.data
          if (feedback[0].feedback === 'Y') {
              this.SnackBar(true, 'success', 'Save Success', 3000)
              this.confirm.dialog  	 = false
              this.confirm.text 	 	 = 'Ok'
              if (this.url_type ==="add") {
                this.form.mapdetail_cuser = feedback[0].feedback_mapdetail_headerid
                this.form.mapdetail_cdate = feedback[0].feedback_mapdetail_date
              }
              this.form.mapdetail_luser = feedback[0].feedback_mapdetail_headerid
              this.form.mapdetail_ldate = feedback[0].feedback_mapdetail_date
              this.url_type 			 = 'edit'
              this.ListMapDetail(this.form.mapheader_id)
          } else {
              this.SnackBar(true, 'error', feedback[0].feedback, 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
          }
        })
        .catch(e => {
          this.SnackBar(true, 'error', 'Check Error Message', 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
      },

      DeleteMapDetail (id, from) {
        let formdata = {
          url_type: this.url_type,
          mapdetail_headerid: id,
          mapdetail_from: from
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiWriteMapDetail'), param,{
          headers: {
              'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === 'Y') {
              this.SnackBar(true, 'success', 'Delete Success', 3000)
              this.confirm.dialog  	 = false
              this.confirm.text 	 = 'Ok'
              this.selected = []
              this.ListMapDetail(id)
            } else {
              this.SnackBar(true, 'error', feedback[0].feedback, 0)
              this.remarks.dialog 	= true
              this.remarks.feedback 	= feedback[0].feedback
            }
          }
          
        })
        .catch(e => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog 	= true
          this.remarks.feedback 	= e
        })
      },
      FieldToData () {
        let formdata = {
          mapheader_type: this.form.mapheader_type
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListMapUploadColumn'), param,{
          headers: {
              'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then((response) => {
          let feedback = response.data
          this.mapdetail.form.group.to = feedback
          
        })
        .catch(e => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog 	= true
          this.remarks.feedback 	= e
        })
      },
      DisplayFile (e) {
          let sheets = this
          sheets.mapdetail.form.group.from = []
          sheets.mapdetail.form.input.label = 'Loading...'
          const file = e.target.files[0]
          var reader = new FileReader()
          reader.fileName = file.name
          reader.onload = function (e) {
              var data = e.target.result
              var workbook = XLSX.read(data, { type: 'binary' })
              sheets.form.filename = e.target.fileName
              let sheetName = workbook.SheetNames[0]
              let worksheet = workbook.Sheets[sheetName]
              let rowObject = XLSX.utils.sheet_to_row_object_array(worksheet,{header:1})
              // const finalJsonData = JSON.stringify(rowObject[0])
              let dataobject = rowObject[0]
              let initialLength = dataobject.length;
              let result = dataobject.push("DATADUMMY");
              if (result === initialLength + 1) {
                console.log("Element 'DATADUMMY' added successfully.");
              } else {
                console.log("Failed to add 'DATADUMMY'.");
              }
              sheets.mapdetail.form.group.from = dataobject
              sheets.mapdetail.form.input.label = 'XLSX File'
          }
          reader.readAsBinaryString(file)
      },
    // ========================================================================
    // End Function Map Detail
    // ========================================================================
		//Function for get all data from table Menu with API in mod_mapupload.go

    // ========================================================================
    // Start Function Map Header
    // ========================================================================
      List () {
        this.loading.list = true
        let formdata = {
          mapheader_id: '',
          mapheader_desc: '',
          order_by: 'mapheader_id',
          order_type: 'ASC',
          limit: this.limit,
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListMapHeader'),param,{
          headers: {
              'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let feedback = response.data
            if (feedback.length > 0) {
              if (feedback[0].feedback !=="Y") {
                this.SnackBar(true, "error", feedback[0].feedback, 0)
              } else {
                this.mapHeadersItems = feedback
              }
            } else {
              this.mapHeadersItems = feedback
              // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
            }
            this.loading.list = false
        })
        .catch(e => {
          this.SnackBar(true, 'error', e, 3000)
          this.loading.list = false
        })
      },
      Add () {
        this.url_type 		 			= 'add'
        this.form.mapheader_id 	 		= ''
        this.form.mapheader_desc 	 		= ''
        this.form.mapheader_type 	 		= ''
        this.form.mapheader_partial 	 		= false
        this.form.mapheader_createprofile 	 		= false
        this.form.mapheader_duplicaterefno 	 		= false
        this.form.mapheader_actived 		= true
        this.form.mapheader_id_readonly 	= true
        setTimeout(function(){
                document.getElementById('map_group').focus()
            }, 500)
      },
      Edit (id) {
        this.loading.list = true
        let formdata = {
          mapheader_id: id,
          mapheader_desc: '',
          order_by: 'mapheader_id',
          order_type: 'ASC',
          limit: this.limit,
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListMapHeader'),param,{
              headers: {
                  'Content-Type': 'text/plain; charset=ISO-8859-1'
              }
        })
        .then(response => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === 'Y') {
              document.getElementById('tabform').click()
              this.url_type 			    	    = 'edit'
              this.form.mapheader_id   	    = feedback[0].mapheader_id
              this.form.mapheader_desc 	    = feedback[0].mapheader_desc
              this.form.mapheader_type 	    = feedback[0].mapheader_type
              this.form.mapheader_actived   = this.$functions.TrueOrFalse(feedback[0].mapheader_actived)
              this.form.mapheader_partial   = this.$functions.TrueOrFalse(feedback[0].mapheader_partial)
              this.form.mapheader_reference = this.$functions.TrueOrFalse(feedback[0].mapheader_reference)
              this.form.mapheader_duplicaterefno = this.$functions.TrueOrFalse(feedback[0].mapheader_duplicaterefno)
              this.form.mapheader_allowed   = this.$functions.TrueOrFalse(feedback[0].mapheader_allowed)
              this.form.mapheader_cdate		  = feedback[0].mapheader_cdate
              this.form.mapheader_cuser		  = feedback[0].mapheader_cuser
              this.form.mapheader_ldate		  = feedback[0].mapheader_ldate
              this.form.mapheader_luser		  = feedback[0].mapheader_luser
              this.form.mapheader_id_readonly  = true
              this.ListMapDetail(feedback[0].mapheader_id)
              this.ListMapCampaign(feedback[0].mapheader_id)
              setTimeout(function(){
                document.getElementById('map_group').focus()
              }, 500)
            } else {
              this.SnackBar(true, 'error', response.data[0].feedback, 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", response.data[0].feedback, 0)
          }
          this.loading.list = false
        })
        .catch(e => {
          this.SnackBar(true, 'error', e, 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
          this.loading.list = false
        })
      },
      Delete (id) {

        let formdata = {
          url_type: "delete",
          mapheader_id: id
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiWriteMapHeader'), param,{
          headers: {
              'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === 'Y') {
              this.SnackBar(true, 'success', 'Delete Success', 3000)
              this.confirm.dialog  	 = false
              this.confirm.text 	 = 'Ok'
              this.selected = []
              this.List()
            } else {
              this.SnackBar(true, 'error', feedback[0].feedback, 0)
              this.remarks.dialog 	= true
              this.remarks.feedback 	= feedback[0].feedback
            }
          }
          
        })
        .catch(e => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog 	= true
          this.remarks.feedback 	= e
        })
      },
      MultiProcess (flag) {
            let multiData = JSON.stringify(this.selected)
            let formdata = {
              url_type: flag,
              multi_data: multiData,
            }
            let param = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST("apiWriteMapHeader"), param, {
              headers: {
                "Content-Type": "text/plain charset=ISO-8859-1",
              },
            })
            .then(response => {
              if (response.data[0].feedback === 'Y') {
                this.SnackBar(true, 'success', 'Success', 3000)
                this.confirm.dialog  	 = false
                this.confirm.text 	 = 'Ok'
            this.List()
          } else {
            this.SnackBar(true, 'error', response.data[0].feedback, 3000)
          }
            })
            .catch(e => {
              this.confirm.dialog  	 = false
                this.confirm.text 	 = 'Ok'
              this.SnackBar(true, 'error', e, 3000)
            })
      },
      Write () {
        let formdata = {
          url_type: this.url_type,
            map_type: 'header',
            mapheader_id: this.form.mapheader_id,
            mapheader_desc: this.form.mapheader_desc,
            mapheader_type: this.form.mapheader_type,
            mapheader_partial: this.$functions.ActivedFlag(this.form.mapheader_partial),
            mapheader_createprofile: this.$functions.ActivedFlag(this.form.mapheader_createprofile),
            mapheader_duplicaterefno: this.$functions.ActivedFlag(this.form.mapheader_duplicaterefno),
            mapheader_actived: this.$functions.ActivedFlag(this.form.mapheader_actived),
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios
          .post(this.$functions.UrlPOST('apiWriteMapHeader'), param,{
          headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let feedback = response.data
          if (feedback[0].feedback === 'Y') {
              this.SnackBar(true, 'success', 'Save Success', 3000)
              this.confirm.dialog  	 = false
              this.confirm.text 	 	 = 'Ok'
              if (this.url_type ==="add") {
                this.form.mapheader_cuser = feedback[0].feedback_users_id
                this.form.mapheader_cdate = feedback[0].feedback_users_date
              }
              this.form.mapheader_luser = feedback[0].feedback_users_id
              this.form.mapheader_ldate = feedback[0].feedback_users_date
              this.form.mapheader_id    = feedback[0].feedback_id
              this.url_type 			 = 'edit'
          } else {
              this.SnackBar(true, 'error', feedback[0].feedback, 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
          }
        })
        .catch(e => {
          this.SnackBar(true, 'error', 'Check Error Message', 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
      },
      MultiProcessMapDetail (flag) {
        let multiData = JSON.stringify(this.mapdetail.table.selected)
        let formdata = {
          url_type: flag,
          multi_data: multiData,
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST("apiWriteMapDetail"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then(response => {
          if (response.data[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Success', 3000)
            this.confirm.dialog  	 = false
            this.confirm.text 	 = 'Ok'
            this.ListMapDetail()
          } else {
            this.SnackBar(true, 'error', response.data[0].feedback, 3000)
          }
        })
        .catch(e => {
          this.confirm.dialog  	 = false
          this.confirm.text 	 = 'Ok'
          this.SnackBar(true, 'error', e, 3000)
        })
      },
      MultiProcessMapCampaign (flag) {
        let multiData = JSON.stringify(this.mapcampaign.table.selected)
        let formdata = {
          url_type: flag,
          multi_data: multiData,
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST("apiWriteMapCampaign"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then(response => {
          if (response.data[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Success', 3000)
            this.confirm.dialog  	 = false
            this.confirm.text 	 = 'Ok'
            this.ListMapCampaign()
          } else {
            this.SnackBar(true, 'error', response.data[0].feedback, 3000)
          }
        })
        .catch(e => {
          this.confirm.dialog  	 = false
          this.confirm.text 	 = 'Ok'
          this.SnackBar(true, 'error', e, 3000)
        })
      },
      MultiProcessMapValue (flag) {
        let multiData = JSON.stringify(this.mapvalue.table.selected)
        let formdata = {
          url_type: flag,
          multi_data: multiData,
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST("apiWriteMapValue"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then(response => {
          if (response.data[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Success', 3000)
            this.confirm.dialog  	 = false
            this.confirm.text 	 = 'Ok'
            this.ListMapValue()
          } else {
            this.SnackBar(true, 'error', response.data[0].feedback, 3000)
          }
        })
        .catch(e => {
          this.confirm.dialog  	 = false
          this.confirm.text 	 = 'Ok'
          this.SnackBar(true, 'error', e, 3000)
        })
      },
      ShowUsers (id) {
        this.dialog.users = true
        this.form.display_users = id
      },
      Variable (flag, position) {
        return this.$functions.Variable(flag,position)
      },
      ClearFile (){
        this.mapdetail.form.group.from = []
      },
      OpenUpload () {
          this.$refs.fileupload.click()
      },
      Dummy () {
        this.mapdetail.form.input.fromfree = 'DATADUMMY'
        setTimeout(function() {
            document.getElementById("mapdetailto").focus()
          }, 500)
      }
    // ========================================================================
    // End Function Map Header
    // ========================================================================
    
	}
}
</script>